
import {
  Text,
  Button,
  Card,
  SimpleGrid,
  PasswordInput,
  LoadingOverlay,
} from "@mantine/core";
import React, { useContext, useState } from "react";
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { UserService } from "../../services/userService";
import { ConfigContext } from "../../Context/configContext";

function ChangePassword(): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [loading, setLoading] = useState(false);

  const userService = new UserService(config!);

  const form = useForm({
    initialValues: {password: '', newPassword: '', confirmPassword:''},
    validate: {
      password: (value) => !value ? "Old Password is required":null,
      newPassword: (value) => !value ? "Password is required": value.length < 8 ? 'Password should be min 8 characters':null,
      confirmPassword: (value, values) => (!value?'Confirm Password is required':value !== values.newPassword ? 'Passwords did not match' : null)
    },
  });

  const handleUpdateUserPassword = async (values:any) => {
      setLoading(true);
      const response = userService.updatePassword(values);
      response.then((res) => {
         setLoading(false);
         showNotification({ color: "green", message: "Password updated!" });
      });
  };

  return (
    <React.Fragment>
      {loading && (
        <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
      <>
      <Card shadow="0">
          <form onSubmit={form.onSubmit(handleUpdateUserPassword)}>
              <Card.Section withBorder inheritPadding py="xs">
                <Text size="sm" mt={10} fw={600}>
                  Change Password
                </Text>
                <SimpleGrid cols={1} mt={10} mb={20}>
                  <PasswordInput w="50%" placeholder="Enter old password" label="Old password" {...form.getInputProps('password')} />
                  <PasswordInput w="50%" placeholder="Enter new password" label="New password" {...form.getInputProps('newPassword')} />
                  <PasswordInput w="50%" placeholder="Confirm password" label="Confirm password" {...form.getInputProps('confirmPassword')} />
                </SimpleGrid>
              </Card.Section>

              <Card.Section withBorder inheritPadding py="xs">
                <Button type="submit" w={150} mt={10} variant="filled" size="xs">
                  Save Changes
                </Button>
              </Card.Section>
          </form>
        </Card>
      </>
    </React.Fragment>
  );
}

export { ChangePassword };
