import {
  LoadingOverlay,
  Paper,
  Text,
  Container,
  Image,
  Center,
  Button,
  Title,
  Grid,
  Space
} from "@mantine/core";
import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from "react";
import logo from "../images/logo.png";
import expired from "../images/cross-2x.png";
import './register.css';
import { Link, useSearchParams  } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { ConfigContext } from "../Context/configContext";
import { AuthService } from "../services/AuthService";

function RegisterSuccess(): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const initialized = useRef(false)
  const [message, setMessage] = useState('');
  const [isVerified, setIsVerified] = useState('PENDING');
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const authService = useMemo(() => new AuthService(config!), [config]);

  const verifyEmailAddress = useCallback(async (code: any) =>  {
     setLoading(true);

     await authService.verifyEmail(code)
      .then((res:any) => {
         setLoading(false)
         if (res?.statusText == 'OK') {
            setIsVerified('COMPLETED');
            setTitle('Thanks for Signing Up');
            setMessage('Congratulations your account has been successfully created.');
         } else {
            if(res?.response?.status == 410) {
                setIsVerified('FAILED');
                setTitle("This link has expired or doesn't exist.");
            }
            setMessage(res?.response?.data);
         }
      })
      .catch((e) => {
          showNotification({ color: "red", message: "Something went wrong!" });
          setLoading(false);
      });
  }, [authService]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      verifyEmailAddress(code);
    }
    console.log("test");
  }, [initialized, code, verifyEmailAddress]);

  return (
    <React.Fragment>
      {loading && (
        <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
      <>
        <Container>
            <Grid>
                <Grid.Col span={{ base: 12, md: 2, lg: 2 }}></Grid.Col>
                <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
                    <div className="register-left">
                        <Paper withBorder p={30}>
                            <Image maw={120} mx="auto" radius="md" src={logo} />
                            <Space  h="md" />
                            <Center>
                                {isVerified == 'COMPLETED' && (
                                    <svg  xmlns="http://www.w3.org/2000/svg"  width="70"  height="70"  viewBox="0 0 24 24"  fill="#30C730"  className="icon icon-tabler icons-tabler-filled icon-tabler-circle-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" /></svg>
                                )}
                                {isVerified == 'FAILED' && (
                                     <Image maw={60} mx="auto" radius="md" src={expired} />
                                )}
                            </Center>
                            <Space  h="md" />
                            {isVerified == 'FAILED' && (
                                <Text ta="center" size="md">{message}</Text>
                            )}
                            {isVerified == 'COMPLETED' && (
                                <div>
                                <Title ta="center" order={3}>{title}</Title>
                                <Space  h="xs" />
                                <Text ta="center" size="md">{message}</Text>
                                </div>
                            )}
                            <Space  h="xl" />
                            <Center>
                            {isVerified == 'COMPLETED' && (
                                <Link to="/login">
                                    <Button variant="filled" size="md"> Login </Button>
                                </Link>
                            )}
                            </Center>
                        </Paper>
                    </div>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 2, lg: 2 }}></Grid.Col>
            </Grid>
        </Container>
      </>
    </React.Fragment>
  );
}

export { RegisterSuccess };
