import {
  LoadingOverlay,
  TextInput,
  PasswordInput,
  Paper,
  Text,
  Button,
  Image,
  Title,
  Grid,
  Space,
  Card,
  Flex,
  Center,
  em
} from "@mantine/core";
import React, { useContext, useState } from "react";
import logo from "../images/logo.png";
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { Link } from 'react-router-dom';
import './login.css';
import loginBanner from '../images/login_banner.jpg';
import { AuthService } from "../services/AuthService";
import { useMediaQuery } from '@mantine/hooks';
import { ConfigContext } from "../Context/configContext";

function Registration(): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [loading, setLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(true);
  const [email, setEmail] = useState(null);
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [time, setTime] = useState("01:");
  const [seconds, setSeconds] = useState(60);
  const [isResend, setIsResend] = useState(false);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const authService = new AuthService(config!);

  const form = useForm({
      initialValues: {name:'',email: '',password: ''},
      validate: {
        name: (value) => !value ? "Name is required":null,
        email: (value) => !value ? 'Email is required':(emailPattern.test(value) ? null : 'Invalid email'),
        password: (value) => !value ? "Password is required": value.length < 8 ? 'Password should be min 8 characters':null
      },
  });

  const timer = () => {
      let intervalId;
      let secondsLeft = 60;
      setTime('01:');
      intervalId = setInterval(() => {
        setTime('00:');
        if (secondsLeft > 0) {
          setSeconds(secondsLeft);
          setSeconds(secondsLeft - 1);
        } else {
          setIsResend(true);
          clearInterval(intervalId);
        }
        secondsLeft--;
      }, 1000);
  };

  const handleRegister = async (values:any) => {
      setLoading(true);
      setEmail(values.email);
      await authService.register(values.email, values.name, values.password)
      .then((res:any) => {
         setLoading(false)
         if (res?.statusText == 'Created') {
           setIsAccountCreated(true);
           setTimeout(function () {
               setIsEmailSent(true);
               showNotification({ color: "green", message: "Verification email sent successfully"});
               timer();
           }, 1000);
         } else {
            if(res?.response?.data == 'Failed to send verification link') {
                setIsEmailSent(false);
            }
            showNotification({ color: "red", message: res?.response?.data});
         }
      })
      .catch((e) => {
          showNotification({ color: "red", message: "Something went wrong!" });
          setLoading(false);
      });
  };

  const resendVerificationEmail = async () => {
    setLoading(true);
    setTime('01:');
    try {
        if(email) {
            await authService.resendEmail(email, 'verification').then((res:any) => {
                 setLoading(false)
                 if (res?.statusText == 'OK') {
                   setTimeout(function () {
                     setSeconds(60);
                     timer();
                     setIsResend(false);
                     setIsEmailSent(true);
                     showNotification({ color: "green", message: "Verification email resent successfully"});
                   }, 1000);
                 } else {
                     if(res?.response?.data == 'Failed to send verification link') {
                         setIsEmailSent(false);
                     }
                     showNotification({ color: "red", message: res?.response?.data});
                 }
            })
        }
    } catch (error) {
         showNotification({ color: "red", message: "Something went wrong !" });
    }
  };

  return (
    <React.Fragment>
      {loading && (
        <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
      <>
        <div className="login-page">
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }} mr={0} bg="#F4F6FB" visibleFrom="sm">
              <Card bg="#F4F6FB">
                <Card.Section  className="login-image">
                  <Text ta="center" c="black" fw="700" className="large-text" mb={40}>
                      Empower your website's data privacy with Cookiex to seamlessly
                      manage cookie consent and compliance today!
                  </Text>
                  <Image src={loginBanner} />
                </Card.Section>
              </Card>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }} ml={0} mt={isMobile?80:0}>
                {isAccountCreated && (
                   <div className="login-left">
                        <Image maw={120} mx="auto" mt="lg" radius="md" src={logo} />
                        <Title order={3} ta="center" mt="md">Please Check Your Inbox</Title>
                        <div className="login-padding">
                            <Text size="sm" ta="center" mt="sm">An email has been sent to verify your email address.</Text>
                            {isEmailSent && (
                                <Center mt="xl">
                                    <Text>Didn't receive email? Resend in </Text>
                                    <Space w={5} />
                                    <Text fw={500}>{time}</Text>
                                    <Text fw={500}>{seconds < 10?'0'+seconds:seconds==60?'00':seconds}</Text>
                               </Center>
                            )}
                           <div>
                             {isResend && (
                                  <Button type="submit" fullWidth mt="xl" mb="xs" onClick={resendVerificationEmail}> Resend </Button>
                              )}
                              {!isResend && (
                                <Button disabled fullWidth mt="xl" mb="xs"> Resend </Button>
                              )}
                           </div>
                        </div>
                   </div>
                )}

                {!isAccountCreated && (
                <Paper className="login-left">
                     <div>
                       <Image maw={120} mx="auto" mt="lg" radius="md" src={logo} />
                       <Title order={3} ta="center" mt="md">Create Your Account</Title>
                       <Text size="sm" ta="center" mt="sm">Sign up for a free account today</Text>
                       <Paper className="login-padding">
                          <form onSubmit={form.onSubmit(handleRegister)}>
                              <Title order={6} mt="md" mb="xs">Name</Title>
                              <TextInput withAsterisk  radius="xs" size="md"  placeholder="Name" {...form.getInputProps('name')} />
                              <Title order={6} mt="md" mb="xs">Email address</Title>
                              <TextInput withAsterisk  radius="xs" size="md"  placeholder="name@example.com"  {...form.getInputProps('email')} />
                              <Title order={6} mt="md" mb="xs">Password</Title>
                              <PasswordInput withAsterisk  radius="xs" size="md"  placeholder="Minimum 8 characters" {...form.getInputProps('password')} />
                              {isEmailSent && (
                                <Button type="submit" fullWidth mt="xl" mb="xs"> Register </Button>
                              )}

                              {!isEmailSent && (
                                <Button color="blue" fullWidth mt="xl" mb="xl" onClick={resendVerificationEmail}> Resend Email </Button>
                              )}
                              <Link to="/login">
                                  <Flex justify="center">
                                      <Text ta="center" c="black" size="sm" mt={20} fw={500}>
                                         Already have an account ?{" "}
                                      </Text>
                                      <Space w={3} />
                                      <Text ta="center" c="blue" size="sm" mt={20} fw={500}>
                                           Login
                                      </Text>
                                  </Flex>
                              </Link>
                          </form>
                       </Paper>
                     </div>
                </Paper>
                 )}
            </Grid.Col>
          </Grid>
        </div>
      </>
    </React.Fragment>
  );
}

export { Registration };
