import React, { createContext, useState, useEffect, useMemo } from 'react';
import { Config } from "../utils/types";

interface ConfigContextProps {
  config: Config | null;
}

export const ConfigContext = createContext<ConfigContextProps>({ config: null });

export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [config, setConfig] = useState<Config | null>(null);

  const defaultConfig: Config = useMemo(() => {
    return {
      apiBaseUrl: 'https://api.in.cookiex.io',
      scanApiBaseUrl: 'https://scanner.cookiex.io',
      bannerUrl: "https://cdn.cookiex.io/banner"
    };
  }, []);

  useEffect(() => {
    fetch('/configs/config.json')
      .then((response) => response.json())
      .then((data) => {
        // Merge fetched data with default configuration
        const configWithDefaults = { ...defaultConfig, ...data };
        setConfig(configWithDefaults);
      })
      .catch((error) => {
        if (error instanceof SyntaxError) {
          console.error('Config syntax broken:', error.message);
        } else {
          console.error('Error fetching config:', error);
        }

        setConfig(defaultConfig);
      });
  }, [defaultConfig]);

  if (!config) {
    return <div>Loading configuration...</div>;
  }

  return (
    <ConfigContext.Provider value={{ config }}>
      {children}
    </ConfigContext.Provider>
  );
};
