import axios from 'axios';
import { getAuthorizationHeader } from "../utils/getAuthorizationHeader";
import { Config } from '../utils/types';

export class ScanService {
  private config: Config;

  constructor(config: Config) {
    this.config = config;
  }

  public async getScanReport(domainId: string): Promise<any> {
    try {
      const response = await axios.get(
        `${this.config.apiBaseUrl}/domains/${domainId}/scans`,
        { headers: getAuthorizationHeader() }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null;
      }
      console.error("Failed to get scan report:", error);
      throw error;
    }
  }

  public async setScanFrequency(domainId: string, frequency: string): Promise<any> {
    try {
      const response = await axios.put(`${this.config.apiBaseUrl}/domains/${domainId}/scans/frequency`,
        { frequency },
        {headers: getAuthorizationHeader()}
      );
      return response.data;
    } catch (error) {
      console.error('Frequency update failed:', error);
      throw error;
    }
  }

  public async initiateScan(domainId: string): Promise<void> {
    try {
      await axios.post(
        `${this.config.scanApiBaseUrl}/scan/domain/${domainId}`,
        {},
        { headers: getAuthorizationHeader() }
      );
    } catch (error) {
      console.error("Failed to initiate scan:", error);
      throw error;
    }
  }

  public async getScanResultById(domainId: string, scanResultId: string): Promise<any> {
    try {
      const response = await axios.get(
        `${this.config.apiBaseUrl}/domains/${domainId}/scans/results/${scanResultId}`,
        { headers: getAuthorizationHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to get scan result:", error);
      throw error;
    }
  }

  public async addCookie(domainId: string, cookieData: any): Promise<void> {
    try {
      await axios.put(
        `${this.config.apiBaseUrl}/domains/${domainId}/scans`,
        cookieData,
        { headers: getAuthorizationHeader() }
      );
    } catch (error) {
      console.error("Failed to add cookie:", error);
      throw error;
    }
  }
} 