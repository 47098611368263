import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useEffect, useRef, useState } from "react";

const PACIFIC_CONFIGS = [
    {
        name:"Asia-Pacific",
        cords: {
          lat: 34.0479,
          lng: 100.6197
        }
    },
    {
        name:"Africa",
        cords: {
          lat: 1.3733,
          lng: 30.0500
        }
    },
    {
        name:"Europe",
        cords: {
          lat: 51.1657,
          lng: 10.4515
        }
    }
];

export default function MapComponent(props){
  const [map, setMap] = useState<google.maps.Map>()
  const ref = useRef<HTMLDivElement>()
  const [markerCluster, setMarkerClusters] = useState<MarkerClusterer>();
  const [marker, setMarker] = useState<{lat: number, lng: number} | undefined>();

  useEffect(()=>{
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
         (position) => {
           const { latitude, longitude } = position.coords;
           setMarker({lat: latitude, lng: longitude});
           if(ref.current && !map){
             let cLat = latitude;
             let cLng = longitude;
             const location = PACIFIC_CONFIGS.filter(e => e.name == props?.mapLocationData);
             if(location.length > 0) {
                cLat = location[0].cords.lat;
                cLng = location[0].cords.lng;
             }
             setMap(new window.google.maps.Map(ref.current, {
               center: {lat: cLat, lng: cLng},
               zoom: 1,
             }))
           }
           if(map && !markerCluster){
               setMarkerClusters(new MarkerClusterer({map, markers: [], }));
           }
         },
         (error) => {
           console.log(error);
         }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, markerCluster])

  useEffect(()=> {
    if(marker && markerCluster){
      markerCluster.clearMarkers();
      markerCluster.addMarker(
        new window.google.maps.Marker({
          position: {lat: marker.lat, lng: marker.lng}
        })
      );
    }
  }, [marker, markerCluster])

  return (
    <>
      <div ref={ref as any} style={{height: "100%", width: "100%", minHeight:"350px"}} ></div>
    </>
  )
}
