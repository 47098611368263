import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/charts/styles.css';
import '@mantine/dates/styles.css'; //if using mantine date picker features
import { Outlet } from "react-router";
import 'mantine-react-table/styles.css';
import './app.css';
import { ThemeProvider } from "./ThemeProvider";
import { UserProvider } from "./Context/useAuth";
import { ConfigProvider } from './Context/configContext';
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from '@mantine/modals';

function App() {
  return (
    <div className="App">
      <ConfigProvider>
        <UserProvider>
          <ThemeProvider>
            <ModalsProvider>
              <Notifications position="top-right" />
              <Outlet />
            </ModalsProvider>
          </ThemeProvider>
        </UserProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
