import axios from "axios";
import { showNotification } from '@mantine/notifications';

export const handleError = (error: any) => {
  if (axios.isAxiosError(error)) {
    var err = error.response;
    if (Array.isArray(err?.data.errors)) {
      for (let val of err?.data.errors) {
        showNotification({ color: "red", message: val.description});
      }
    } else if (typeof err?.data.errors === "object") {
      for (let e in err?.data.errors) {
        showNotification({ color: "red", message: err.data.errors[e][0]});
      }
    } else if (err?.data) {
      showNotification({ color: "red", message: err.data});
      return err?.data;
    } else if (err?.status == 401) {
      showNotification({ color: "red", message: "Please login"});
      window.history.pushState({}, "LoginPage", "/login");
    } else if (err) {
      showNotification({ color: "red", message: err?.data});
      return err?.data;
    }
  }
};
