import {
  Grid,
  Text,
  Container,
  Space,
  Title,
  Group,
  Stack,
  Button,
  Card,
  Progress,
  Select,
  Checkbox,
} from "@mantine/core";
import React from "react";
import { useTitle } from "../hooks/useTitle";

function PrivacyPolicyPage(): JSX.Element {
  useTitle("Your Privacy Policy");

  return (
    <React.Fragment>
      <>
        <Container fluid>
          <Space h="lg" />
          <Title order={2}>Privacy Policy</Title>
          <Space h="lg" />
          <Card withBorder shadow="sm" radius="md">
            <Card.Section withBorder inheritPadding py="xs">
              <Group justify="space-between">
                <Text fw={500}>Create Privacy Policy</Text>
              </Group>
              <Space h="lg" />
              <Progress.Root size="xl">
                <Progress.Section value={5}>
                  <Progress.Label>0 %</Progress.Label>
                </Progress.Section>
              </Progress.Root>
            </Card.Section>

            <Card.Section withBorder mt="sm" p="md">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
                  <Title order={6}>Where are you located ?</Title>
                  <Space h="xs" />
                  <Select
                    placeholder="Select"
                    data={["React", "Angular", "Vue", "Svelte"]}
                  />
                  <Space h="xs" />
                  <Text size="xs">
                    Your privacy policy will be adapted for the location you
                    select.
                  </Text>
                </Grid.Col>
              </Grid>
            </Card.Section>

            <Card.Section withBorder inheritPadding mt="sm" pb="md">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
                  <Stack>
                    <Title order={6}>
                      Where will your privacy policy be used?
                    </Title>
                    <Checkbox label="Mobile application" />
                    <Checkbox label="Website" />
                  </Stack>
                </Grid.Col>
              </Grid>
            </Card.Section>

            <Card.Section withBorder inheritPadding mt="sm" pb="md">
              <Button variant="filled" size="xs">
                Next
              </Button>
            </Card.Section>
          </Card>
        </Container>
      </>
    </React.Fragment>
  );
}

export { PrivacyPolicyPage };
