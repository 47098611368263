import {
  Image,
  Group,
  Button,
  Menu,
  Divider,
  Avatar,
  Select,
  Text,
  Burger,
  em
} from "@mantine/core";
import { IconCrown, IconUser, IconLogout } from "@tabler/icons-react";
import classes from "./header.module.css";
import logo from "../../images/logo.png";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../Context/useAuth";
import { ConsentService } from "../../services/consentService";
import { consentRecordsResponse } from "../../Models/consentRecordsResponse";
import { useMediaQuery } from '@mantine/hooks';
import { getInitials, generateColorFromName, getName } from "../../utils/utils";
import { ConfigContext } from "../../Context/configContext";

export function AppHeader(props:any) {
  const { config } = useContext(ConfigContext);
  const [domainData, setDomainData] = useState([]);
  const [consentDomainData, setConsentDomainData] = useState<consentRecordsResponse[]>([]);
  const [defaultSelection, setDefaultSelection] = useState<any | null>(null);
  const router = useNavigate();
  const { logout } = useAuth();
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const consentService = new ConsentService(config!);

  useEffect(() => {
    if(!localStorage.getItem('activeDomain')) {
        const response = consentService.getConsents();
        response.then((res) => {
           let tempData = res.data.map(function(element) {
               return {value: element.domainId, label: element.domain};
           });

           setDomainData(tempData);
           setConsentDomainData(res.data);
           
           if (tempData && tempData.length === 1) {
            localStorage.setItem("activeDomain", tempData[0].label);
            localStorage.setItem("activeDomainId", tempData[0].value);
              setDefaultSelection(localStorage.getItem('activeDomain'));
              if(props.activeConsentData) {
                  const result = res.data.filter( e => e.domain == localStorage.getItem('activeDomain'));
                  props.activeConsentData(result);
              }
           }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCurrentDomain = (cValue) => {
      localStorage.setItem("activeDomain", cValue);
      if(props.activeConsentData && consentDomainData.length > 0) {
          const result = consentDomainData.filter( e => e.domain == cValue);
          localStorage.setItem("activeDomainId", result[0].domainId);
          props.activeConsentData(result);
      }
      router(0);
  };

  return (
    <header className={classes.header}>
      <div className={classes.inner}>
        <Group ml={isMobile?10:45}>
          <Burger
            opened={props.opened}
            onClick={props.toggle}
            hiddenFrom="sm"
            size="sm"
          />
          <Image
            src={logo}
            alt="Logo"
            style={{
              width: "100%",
              maxWidth: isMobile ? "70px" : '170px', // Adjust size for mobile vs desktop
              height: "auto",
              objectFit: "contain",
            }}
          />
          <Group ml={50} gap={15} style={{display:'none'}}>
            {domainData.length > 0 && (
                <Select defaultValue={defaultSelection} placeholder="Please select your Domain" style={{width:'15rem'}} data={domainData}  onChange={(event) => handleCurrentDomain(event)} />
            )}
          </Group>
        </Group>
        <Group>
         {localStorage.getItem("activeDomainId") && (
              <Group ml={50} gap={5} className={classes.links}>
                <Button variant="filled" size="xs" onClick={() => {router(`/pricing?domainId=${localStorage.getItem("activeDomainId")}`)}}>
                  <IconCrown stroke={1.3} /> &nbsp; <Text size="xs"> Upgrade{" "} </Text>
                </Button>
              </Group>
          )}
          <Group ml={10} gap={5} className={classes.links}>
            <Menu width={200} position="bottom-end" transitionProps={{ transition: "pop-top-right" }} withinPortal>
              <Menu.Target>
                <Group style={{ cursor: 'pointer' }}>
                  <Avatar
                    size="sm"
                    color="white"
                    style={{
                      backgroundColor: generateColorFromName(props.user && getName(props.user.email)),
                      textTransform: 'uppercase',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                  {getInitials(props.user && getName(props.user.email))}
                </Avatar>
                </Group>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={() => {router("/profile")}} leftSection={<IconUser size={15} />}> User Profile</Menu.Item>
                <Divider />
                <Menu.Item  onClick={() => {
                    logout();
                    router("/login");
                }} leftSection={<IconLogout size={15} />}>Logout</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </div>
    </header>
  );
}
