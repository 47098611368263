import { useEffect } from "react";

export const useTitle = (title: string) => {
  useEffect(() => {
    const prevTitle = document.title;
      document.title = `CookieX: ${title}`;

      return () => {
        document.title = prevTitle;
      };
  }, [title]);
}
