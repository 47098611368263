export const generateColorFromName = (email: string) => {
  const name = email.split("@")[0]; // Extract part before "@"
  const hash = name.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return `hsl(${hash % 360}, 40%, 50%)`; // Use HSL format correctly
};


export const getInitials = (email: string) => {
  const name = email.split("@")[0]; // Extract part before "@"
  const parts = name.split("."); // Split name by dots if present, e.g., "john.doe"
  return parts.length > 1
    ? parts[0][0].toUpperCase() + parts[1][0].toUpperCase() // First initial of each part
    : name.substring(0, 2).toUpperCase(); // First two letters of the name if no dot
};


export const getName = (email: string) => {
  const name = email.split("@")[0]; 
  return name;
};

export const formatDate = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zeros if necessary
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

export const regulations = [
  { label: 'GDPR', value: 'gdpr', description: 'The selected opt-in banner supports GDPR (EU & UK), LGPD (Brazil), PIPEDA (Canada), Law 25 (Quebec), POPIA (South Africa), nFADP (Switzerland), Privacy Act (Australia), PDPL (Saudi Arabia), PDPL (Argentina), PDPL (Andorra), DPA (Faroe Island), DPDP (India)' },
  { label: 'US State Laws', value: 'us', description: 'The selected opt-out banner supports CCPA/CPRA (California), VCDPA (Virginia), CPA (Colorado), CTDPA (Connecticut), & UCPA (Utah)' },
];