import {
  Paper,
  Text,
  Container,
  Image,
  Center,
  Button,
  Title,
  Grid,
  Space
} from "@mantine/core";
import React from "react";
import logo from "../images/logo.png";
import './register.css';
import failed from "../images/failed.png";
import success from "../images/success.png";
import expired from "../images/cross-2x.png";
import { Link, useSearchParams  } from 'react-router-dom';

function PasswordSuccess(): JSX.Element {
  const [searchParams] = useSearchParams();
  const message = searchParams.get("message");

  return (
    <React.Fragment>
      <>
        <Container>
            <Grid>
                <Grid.Col span={{ base: 12, md: 2, lg: 2 }}></Grid.Col>
                <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
                    <div className="password-success">
                        {message == 'SUCCESS' && (
                            <Paper withBorder p={30}>
                                <Image maw={120} mx="auto" radius="md" src={logo} />
                                <Space  h="md" />
                                <Center>
                                    <Image maw={120} mx="auto" radius="md" src={success} />
                                </Center>
                                <Space  h="md" />
                                <Title ta="center" order={3}>Your password has been successfully reset!</Title>
                                <Space  h="xs" />
                                <Text ta="center" size="xs">You have successfully updated your password. Please log in with your new password.</Text>
                                <Space  h="xl" />
                                <Center>
                                <Link to="/login">
                                    <Button variant="filled" size="md"> Login </Button>
                                </Link>
                                </Center>
                            </Paper>
                        )}
                        {message == 'FAILED' && (
                            <Paper withBorder p={30}>
                                <Image maw={120} mx="auto" radius="md" src={logo} />
                                <Space  h="md" />
                                <Center>
                                    <Image maw={120} mx="auto" radius="md" src={failed} />
                                </Center>
                                <Space  h="md" />
                                <Title ta="center" order={3}>Password Reset Unsuccessful</Title>
                                <Space  h="xs" />
                                <Text ta="center" size="md">We couldn't complete your password reset because the verification code is invalid and we couldn't find your account.</Text>
                                <Space  h="xl" />
                                <Center>
                                <Link to="/resetPassword">
                                    <Button w="300" variant="filled" size="md"> Try Again </Button>
                                </Link>
                                </Center>
                            </Paper>
                        )}

                        {message == 'EXPIRED' && (
                            <Paper withBorder p={30}>
                                <Image maw={120} mx="auto" radius="md" src={logo} />
                                <Space  h="md" />
                                <Center>
                                    <Image maw={60} mx="auto" radius="md" src={expired} />
                                </Center>
                                <Space  h="md" />
                                <Text ta="center" size="md">This link has expired or doesn't exist. Please check your email for the most recent link or request a new one.</Text>
                                <Space  h="xl" />
                                <Center>
                                <Link to="/resetPassword">
                                    <Button w="300" variant="filled" size="md"> Try Again </Button>
                                </Link>
                                </Center>
                            </Paper>
                        )}
                    </div>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 2, lg: 2 }}></Grid.Col>
            </Grid>
        </Container>
      </>
    </React.Fragment>
  );
}

export { PasswordSuccess };
