import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import { LogInPage } from "../Pages/LogInPage";
import { RegisterSuccess } from "../Pages/RegisterSuccess";
import { PasswordSuccess } from "../Pages/PasswordSuccess";
import { ResetPassword } from "../Pages/ResetPassword";
import { UpdatePassword } from "../Pages/UpdatePassword";
import { Registration } from "../Pages/Registration";
import { Analytics } from "../Pages/Analytics";
import { Dashboard } from "../Pages/Dashboard";
import { Domains } from "../Pages/Domains";
import { Cookies } from "../Pages/Cookies";
import { CookieReport } from "../Pages/CookieReport";
import { ConsentBanners } from "../Pages/ConsentBanners";
import { Configuration } from "../Pages/Configuration";
import { PrivacyPolicy } from "../Pages/PrivacyPolicy";
import { Integration } from "../Pages/Integration";
import { Profile } from "../Pages/Profile";
import { Pricing } from "../Pages/Pricing";
import ProtectedRoute from "./ProtectedRoute";
import { GtmInstallation } from "../Pages/gtmInstallation";
import { PrivacyAuditDetails } from "@/Pages/PrivacyAuditDetails";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "login",
        element: <LogInPage />,
      },
      { path: "gtmInstallation", element: <GtmInstallation /> },
      { path: "register", element: <Registration /> },
      { path: "verification", element: <RegisterSuccess /> },
      { path: "resetPassword", element: <ResetPassword /> },
      { path: "reset", element: <UpdatePassword /> },
      { path: "update", element: <PasswordSuccess /> },
      {
        path: "/analytics",
        element: (
          <ProtectedRoute>
            <Analytics />
          </ProtectedRoute>
        ),
      },
      {
        path: "/pricing",
        element: (
          <ProtectedRoute>
            <Pricing />
          </ProtectedRoute>
        ),
      },
      {
        path: "/integration",
        element: (
          <ProtectedRoute>
            <Integration />
          </ProtectedRoute>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "/domains",
        element: (
          <ProtectedRoute>
            <Domains />
          </ProtectedRoute>
        ),
      },
      {
          path: "/privacy-audits",
          element: (
            <ProtectedRoute>
              <Cookies />
            </ProtectedRoute>
          ),
      },
      {
        path: "/cookieReport",
        element: (
          <ProtectedRoute>
            <CookieReport />
          </ProtectedRoute>
        ),
      },
      {
        path: "/privacy-audit-details",
        element: (
          <ProtectedRoute>
            <PrivacyAuditDetails />
          </ProtectedRoute>
        ),
      },
      {
          path: "/configuration",
          element: (
              <ProtectedRoute>
                <Configuration />
              </ProtectedRoute>
          ),
      },
      {
          path: "/consentBanners",
          element: (
            <ProtectedRoute>
              <ConsentBanners />
            </ProtectedRoute>
          ),
      },
      {
          path: "/privacy-policy",
          element: (
             <ProtectedRoute>
                <PrivacyPolicy />
             </ProtectedRoute>
          ),
      },
      {
          path: "/profile",
          element: (
              <ProtectedRoute>
                  <Profile />
              </ProtectedRoute>
          ),
      }
    ],
  },
]);
