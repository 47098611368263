
import {
  Text,
  Group,
  Button,
  Card,
  Select,
  SimpleGrid,
  Radio,
  TextInput,
  LoadingOverlay,
} from "@mantine/core";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import countries from '../../data/countries.json'; // Adjust the path as necessary
import { UserService } from "../../services/userService";
import { ConfigContext } from "../../Context/configContext";

function ProfileInformation(): JSX.Element {
  const { config } = useContext(ConfigContext);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const initialized = useRef(false);
  const [loading, setLoading] = useState(false);

  const userService = new UserService(config!);

  const form = useForm({
      initialValues: {
        accountType: '',
        invoiceEmail: '',
        purchaseOrderNumber: '',
        vatNumber: '',
        companyName: '',
        billingCycle: '',
        currency: '',
        language: '',
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        country: '',
        city: '',
        postalCode: '',
      },
      validate: {
        accountType: (value) => !value ? "Account Type is required":null,
        invoiceEmail: (value) => !value ? 'Invoice email is required':(emailPattern.test(value) ? null : 'Invalid email'),
        purchaseOrderNumber: (value) => !value ? "Purchase Order Number is required":null,
        vatNumber: (value) => !value ? "Vat Number is required":null,
        companyName: (value) => !value ? "Company is required":null,
        billingCycle: (value) => !value ? "Billing Cycle is required":null,
        currency: (value) => !value ? "Currency is required":null,
        language: (value) => !value ? "Language is required":null,
        firstName: (value) => !value ? "First Name is required":null,
        lastName: (value) => !value ? "Last Name is required":null,
        country: (value) => !value ? "Country is required":null,
        city: (value) => !value ? "City is required":null,
        postalCode: (value) => !value ? "Postal Code is required":null
      },
  });

  useEffect(() => {
      if (!initialized.current) {
        initialized.current = true
        getProfile();
      }
      console.log("test");
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  const getProfile = () => {
     setLoading(true);
     const response = userService.getProfile();
     response.then((res) => {
          setLoading(false);
          if(res){
            console.log(res);
            form.setValues(res);
          }
     });
  };

  const handleProfileInformation = async (values:any) => {
      setLoading(true);
      const response = userService.saveOrUpdateProfile(values);
      response.then((res) => {
         setLoading(false);
         getProfile();
         showNotification({ color: "green", message: "Profile updated!" });
      });
  };

  return (
    <React.Fragment>
      {loading && (
        <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
      <>
      <Card shadow="0">
        {form && (
          <form onSubmit={form.onSubmit(handleProfileInformation)}>
               <Card.Section withBorder inheritPadding py="xs">
                 <Radio.Group label="Account Type" mt={10} mb={20} {...form.getInputProps('accountType')}>
                   <Group mt="xs">
                     <Radio value="Company" label="Company" />
                     <Radio value="OrganisationNGO" label="Organisation/NGO" />
                     <Radio value="PrivateIndividual" label="private Individual" />
                   </Group>
                 </Radio.Group>
               </Card.Section>
               <Card.Section withBorder inheritPadding py="xs">
                 <Text size="sm" mt={10} fw={600}>
                   Invoice Information
                 </Text>
                 <SimpleGrid cols={2} mt={10} mb={20}>
                   <TextInput placeholder="E.g invoice@gmail.com" label="Invoice e-mail" {...form.getInputProps('invoiceEmail')} />
                   <TextInput placeholder="Enter PO number" label="Purchase order number" {...form.getInputProps('purchaseOrderNumber')} />
                   <TextInput placeholder="Enter Company Name" label="Company name" {...form.getInputProps('companyName')} />
                   <TextInput placeholder="Enter VAT" label="VAT number"  {...form.getInputProps('vatNumber')} />
                   <Select defaultValue="Monthly" label="Billing cycle" placeholder="" data={["Monthly", "Yearly"]} {...form.getInputProps('billingCycle')} />
                   <Select defaultValue="USD" label="Currency" placeholder="" data={["USD", "INR"]}  {...form.getInputProps('currency')}/>
                   <Select defaultValue="English" label="Language" placeholder="" data={["English"]}  {...form.getInputProps('language')}/>
                 </SimpleGrid>
               </Card.Section>
               <Card.Section withBorder inheritPadding py="xs">
                 <Text size="sm" mt={10} fw={600}>
                   Billing Information
                 </Text>
                 <SimpleGrid cols={2} mt={10}>
                   <TextInput placeholder="Enter first name" label="First Name" {...form.getInputProps('firstName')} />
                   <TextInput placeholder="Enter last name" label="Last Name"  {...form.getInputProps('lastName')}/>
                 </SimpleGrid>

                 <SimpleGrid cols={1} mt={10}>
                   <TextInput placeholder="Address Line 1" label="Address" {...form.getInputProps('addressLine1')} />
                   <TextInput placeholder="Address Line 2 (Optional)" {...form.getInputProps('addressLine2')} />
                 </SimpleGrid>

                 <SimpleGrid cols={2} mt={20} mb={20}>
                   <Select placeholder="Select country" label="Country" data={countries} searchable {...form.getInputProps('country')} />
                   <TextInput placeholder="Enter postal code" label="Postal code" {...form.getInputProps('city')} />
                   <TextInput placeholder="City" label="City" {...form.getInputProps('postalCode')} />
                 </SimpleGrid>
               </Card.Section>
               <Card.Section withBorder inheritPadding py="xs">
                 <Button type="submit" w={150} mt={10} variant="filled" size="xs">
                   Save Changes
                 </Button>
               </Card.Section>
            </form>
          )}
         </Card>
      </>
    </React.Fragment>
  );
}

export { ProfileInformation };
