import {
  Text,
  Container,
  Space,
  Card,
  Group,
  Alert,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { Step5 } from "./consent/Step5";
import { ConsentService } from "../../services/consentService";
import { consentBannerConfig } from "../../Models/consentBannerConfig";
import { showNotification } from '@mantine/notifications';
import { ThemeDetails } from "../../Models/themeDetails";
import { ConsentOptionsConfig } from "../../Models/consentOptionsConfig";
import { ConfigContext } from "../../Context/configContext";
import { useTitle } from "../hooks/useTitle";
import DomainDropdown from "./DomainDropdown";
import { IconInfoCircle } from "@tabler/icons-react";

const consentOptionsData : ConsentOptionsConfig[] =  [{checked: true,label: "Necessary"},{checked: false,label: "Preferences"},{checked: false,label: "Statistics"},{checked: false,label: "Marketing"}];
const themesConfig: ThemeDetails[] = [
    {background:"#fff",type:"Light",buttonStyle:"Mixed",textColor:"#000",highlight:"#0078b4",buttonCustomizeBackGround:"#0078b4",buttonCustomizeTextColor:"white",buttonCustomizeVariant:"filled",buttonCustomizeBorder:"#0078b4",buttonRejectBackGround:"#0078b4",buttonRejectTextColor:"white",buttonRejectVariant:"filled",buttonRejectBorder:"#0078b4",buttonAcceptBackGround:"#0078b4",buttonAcceptTextColor:"white",buttonAcceptVariant:"filled",buttonAcceptBorder:"#0078b4",consentOptions:consentOptionsData},
];

const tempObj = {language:'en',domainId:'',domainUrl:'',geoLocations:[],consentExpire:'',styles:'', locations:[]};
function ConsentBannerPage(props): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [domainId, setDomainId] = useState<any | null>(localStorage.getItem('activeDomainId'));
  const [domainName, setDomainName] = useState<any | null>(localStorage.getItem('activeDomain'));
  const [consentConfigRequest, setConsentConfigRequest] = useState<consentBannerConfig>(tempObj);
  const [configuredTheme, setConfiguredTheme] = useState<any | null>(null);
  
  const consentService = new ConsentService(config!);

  useTitle("Manage Consent Banner");

  useEffect(() => {
      if(domainId) {
        getConsentById(domainId);
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConsentById = async (id) => {
    const response = consentService.getConsentById(id);
    response.then((res) => {
       if(res?.data){
           setConsentConfigRequest(res?.data);

           if(res?.data?.theme) {
              setConfiguredTheme(JSON.parse(res?.data?.theme));
           } else {
               setConfiguredTheme(themesConfig[0]);
           }

           localStorage.setItem("activeDomain", res?.data?.domainUrl);
       }
    });
  }

  const publishChanges = (stepNo) => {
    showNotification({ color: "green", message: "Update successfully!" });
  };

  const handleConsentData = (fieldValue, fieldName) => {

       if(fieldName == 'styles') {
           consentConfigRequest[fieldName] = JSON.stringify(fieldValue);
       }

       consentConfigRequest['language'] = fieldValue.language;

       if(consentConfigRequest.locations) {
            consentConfigRequest['geoLocations'] = consentConfigRequest.locations;
       }

       const response = consentService.updateConsent(domainId, consentConfigRequest);
       response.then((res) => {
           if(res) {
                setDomainId(res?.id);
                setDomainName(res?.domainUrl);
                showNotification({ color: "green", message: "Update successfully!" });
           }
       });
  };

  const handleDomainSelection = (domain, name) => {
    setDomainId(null);
    setDomainName(null);
    setConsentConfigRequest(tempObj); // Reset consent configuration
    setConfiguredTheme(null); // Clear the theme

    // Set the new domain information
    setDomainId(domain);
    setDomainName(name.label || name);

    // Fetch the new consent data for the selected domain
    getConsentById(domain);
  };

  return (
    <React.Fragment>
      <>
        <Container fluid>
           <Space h="lg" />
            <div>
              <Card shadow="sm" p="xl"  mb="sm">
                    <Group justify="space-between" mb={20}>
                      <DomainDropdown handleCurrentDomain={handleDomainSelection} consentService={consentService} defaultSelection={null} />
                    </Group>
                  <Text size="xs">
                      Set up the user interface of your consent banner, cookie declaration and privacy trigger.
                  </Text>
              </Card>
              <Alert variant="light" color="orange" mb={20}>
                  <Group gap={3}>
                    <IconInfoCircle />
                    <Text fs="italic" size="sm">
                        <strong>Legal disclaimer: </strong> 
                        Please note that we recommend checking your setup with your Data Protection Officer.
                    </Text>
                  </Group>  
              </Alert>
              {domainId && configuredTheme && (
                <Step5 forward={publishChanges} backward={null} screenSize={null} consentData={handleConsentData} consentConfigData={configuredTheme} domainId={domainId} domainName={domainName}/>
              )}
            </div>
        </Container>
      </>
    </React.Fragment>
  );
}

export { ConsentBannerPage };
