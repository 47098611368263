import { Button, Card, Code, Group, Stack, Text, Textarea, Title } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { IconCopy } from "@tabler/icons-react";
import { ConfigContext } from "../../../Context/configContext";
import { useContext } from "react";

function Integration({ nextStep, prevStep, domainId, domainName }) {
    const { config } = useContext(ConfigContext);
    const clipboard = useClipboard({ timeout: 500 });
    
    const embScript = `
        <script id="cookiex" data-ckid=${domainId} src="${config?.bannerUrl}/cookiex.min.js" type="text/javascript"></script>
        <script type="text/javascript">
        document.addEventListener("DOMContentLoaded", function() {
                const theme = {
                    domainId: '${domainId}',
                    domainName: '${domainName}'
                };
                const cookiex = new Cookiex();
                cookiex.init(theme);
        });
        </script>
    `;
    return (
      <>
      <Stack  gap={10} p="sm" pl="xl" pr="xl">
        <Title order={2} fw={500} ta="center">Integration</Title>
        <Text size="md" fw={700}>
            Embed Script
        </Text>
        <Text size="xs" mt={10}>
            Copy the script tag and insert it as the very first script within the HEAD-tag of your website to display the cookie consent banner.
        </Text>
        <Card mt={10} p={0} shadow="0" withBorder style={{ width: '100%' }}>
            <Group justify="apart" grow>
                <Code color="white" style={{minWidth:'250px'}}>
                    <Text size="xs" truncate="end">{embScript}</Text>
                </Code>
                <Text mb="xs" mt="sm" ta="center" color="blue" style={{cursor: 'pointer'}} onClick={() => {
                    clipboard.copy(embScript)
                        showNotification({ color: "green", message: "Copied to clipboard !"});
                    }
                }>
                    <IconCopy color="blue" size={18} />
                </Text>
            </Group>
        </Card>
        <Text size="md" mt={10} fw={700}>
            Domain ID for Wordpress
        </Text>
        <Text size="xs" mt={10}>
            For Wordpress websites, copy the domain ID and put it in the Cookiex WordPress plugin to display the cookie consent banner on your website.
        </Text>
        <Card mt={10} p={0} shadow="0" withBorder style={{ width: '100%' }}>
        <Textarea readOnly
            value={domainId}
            rightSectionPointerEvents="all" w="98%" autosize
            rightSection={
                <IconCopy color="blue" size={18} style={{cursor: 'pointer', color:'blue'}} onClick={() => {
                    clipboard.copy(domainId);
                    showNotification({ color: "green", message: "Copied Domain ID !"});
                    }
                } />
            }
        ></Textarea>
        </Card>
        <Group mt={20} justify="apart" grow>
          <Button variant="default" onClick={prevStep}>Back</Button>
          <Button onClick={nextStep}>Next</Button>
        </Group>
      </Stack>
      </> 
    );
  }

  export { Integration };