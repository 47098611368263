import { MantineProvider, createTheme } from "@mantine/core";

interface ThemeProviderProps {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  const theme = createTheme({
      breakpoints: {
        xs: '30em',
        sm: '48em',
        md: '64em',
        lg: '74em',
        xl: '90em',
      },
      colors: {
        // Add your color
        blue: [
          '#e6f7ff',
          '#b3e6ff',
          '#80d5ff',
          '#4dc4ff',
          '#1ab3ff',
          '#0099e6',
          '#0078b4',
          '#005580',
          '#00334d',
          '#00111a'
        ],
        // or replace default theme color
        green: [
          '#e8f5e9',
          '#c8e6c9',
          '#a5d6a7',
          '#81c784',
          '#66bb6a',
          '#4caf50',
          '#43a047',
          '#388e3c',
          '#2e7d32',
          '#1b5e20'
        ],
      },
      primaryColor: "blue"
  });

  return (
    <MantineProvider theme={theme}>
      {children}
    </MantineProvider>
  );
}
