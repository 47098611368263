
import React from "react";
import { GtmInstallationPage } from "../Components/public/GtmInstallationPage";
 
function GtmInstallation(): JSX.Element {
    return (
      <React.Fragment>
        <>
           <GtmInstallationPage /> 
        </>
      </React.Fragment>
    );
}
  
export { GtmInstallation };
  