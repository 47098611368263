import { Container, Title, Text, List, Divider, Code, Anchor, Image, Group, Flex } from "@mantine/core";
import logo from "../../images/logo.png";

function GtmInstallationPage() {
  return (
    <Container size="responsive" py={50} px={200}>
      <Flex justify="center" align="flex-start">
        <Image width={59} h={59} src={logo} mb={30} />
      </Flex>
      <Divider mb="lg" />
      <Title order={1} mb="md">
        How to use Termly with Google Tag Manager
      </Title>
      <Divider mb="lg" />
      <Title order={1} mb="md">
        Introduction to Google Tag Manager (GTM)
      </Title>
      <Text mb="sm">
        Google Tag Manager (GTM) is designed to help website owners organize and deploy scripts and tags on their site. When using GTM, websites are still subject to global data regulations such as the GDPR, PIPEDA, CCPA, and more.
      </Text>
      <Text mb="sm">
      Businesses under these regulations who use GTM to deploy third-party tracking technologies (e.g., Google Analytics, Facebook Pixel, LinkedIn, AdWords, etc.) must ensure that user consent choices are respected for people visiting their site.
      </Text>
      <Title order={3} mb="md">
      Understanding Google Tag Manager and Consent Mode
      </Title>
      <List withPadding>
        <List.Item>Implement the cookie consent banner</List.Item>
        <List.Item>Control cookie-setting tags</List.Item>
        <List.Item>Display the cookie declaration on a subpage</List.Item>
      </List>

      <Text mt="md">
        Ensure that you've already created a GTM account, a website container in GTM, and added the GTM container snippet to your website as required:{" "}
        <Anchor href="https://support.google.com/tagmanager/answer/6103696" target="_blank">
          https://support.google.com/tagmanager/answer/6103696
        </Anchor>
      </Text>

      <Divider my="lg" />

      {/* Subsection */}
      <Title order={3} mb="sm">
      Step 1: Implementing the Cookie Consent Banner in GTM
      </Title>
      <Text>
        Obtain the CookieX CMP tag template from the Template Gallery. The easiest way to do this is by clicking the Get Started button on the Consent Overview.
      </Text>

      <Group justify="center" my="md">
        <Image
          src="https://via.placeholder.com/500x300"
          alt="Get Started Button"
        />
      </Group>

      <Text>
        To find the Consent Overview, click the <Code>🛠</Code> icon on the Tags section. If the icon is unavailable, enable it in the Admin tab under Container Settings by checking the{" "}
        <Code>Enable consent overview</Code> box.
      </Text>

      <Divider my="lg" />

      {/* Section 2 */}
      <Title order={2} mb="md">
        Step 2: Controlling Cookies
      </Title>
      <Text>
        Google Tag Manager includes features to manage how tags behave based on user consent choices. Some tags have built-in consent checks (Advanced Consent Mode), while others require configuration (Basic Consent Mode).
      </Text>

      <Text mt="md">
        Tags configured with additional consent checks will not fire unless consent has been given for the corresponding storage category. Configure these in the <Code>Advanced Settings → Consent Settings</Code> section of the Tag Editor.
      </Text>

      <Group justify="center" my="md">
        <Image
          src="https://via.placeholder.com/500x300"
          alt="Consent Overview"
        />
      </Group>

      <Divider my="lg" />

      {/* Section 3 */}
      <Title order={2} mb="md">
        Step 3: Implementing the Cookie Declaration
      </Title>
      <Text>
        To allow users to change or withdraw consent, implement CookieX's 'Cookie Declaration' on a page by embedding the following script tag:
      </Text>

      <Code block>
        {`<script
  id="CookieDeclaration"
  src="https://consent.CookieX.com/00000000-0000-0000-0000-000000000000/cd.js"
  type="text/javascript"
></script>`}
      </Code>

      <Text mt="sm">
        Replace <Code>00000000-0000-0000-0000-000000000000</Code> with your own CookieX ID. Ensure this page is linked in the footer or other global sections of your website.
      </Text>

      <Divider my="lg" />

      {/* Section 4 */}
      <Title order={2} mb="md">
        Step 4: Marking Up Non-GTM Elements
      </Title>
      <Text>
        Tags inserted directly into your website template (e.g., scripts, images, iframes) need manual markup for prior consent. Refer to CookieX's manual markup guide for instructions.
      </Text>

      <Text mt="md">
        To combine GTM and automatic blocking, see:{" "}
        <Anchor
          href="https://support.CookieX.com"
          target="_blank"
        >
          Google Tag Manager and Automatic Cookie Blocking
        </Anchor>
      </Text>

      <Divider my="lg" />

      {/* Additional Resources */}
      <Title order={2} mb="md">
        Additional Resources
      </Title>
      <List withPadding>
        <List.Item>
          <Anchor
            href="https://support.CookieX.com"
            target="_blank"
          >
            Multilingual Support in GTM
          </Anchor>
        </List.Item>
        <List.Item>
          <Anchor
            href="https://support.CookieX.com"
            target="_blank"
          >
            Alternative Methods for Conditionally Loading Tags
          </Anchor>
        </List.Item>
        <List.Item>
          <Anchor
            href="https://support.CookieX.com"
            target="_blank"
          >
            Advanced Consent Mode Overview
          </Anchor>
        </List.Item>
      </List>
    </Container>
  );
}

export { GtmInstallationPage };

