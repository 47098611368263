import { useState } from "react";
import { Text } from "@mantine/core";
import {
  IconHome,
  IconPolaroid,
  IconSettings,
  IconReportAnalytics,
  IconHeadset,
  IconWorld,
  IconReport,
} from "@tabler/icons-react";
import classes from "./sidebar.module.css";
import { Link } from 'react-router-dom';

const data = [
  { link: "/dashboard", label: "Home", icon: IconHome },
  { link: "/domains", label: "Domains", icon: IconWorld },
  { link: "/privacy-audits", label: "Privacy Audits", icon: IconReport },
  { link: "/consentBanners", label: "Consent Banner", icon: IconPolaroid },
  { link: "/integration", label: "Integration", icon: IconSettings },
  { link: "/analytics", label: "Analytics", icon: IconReportAnalytics },
  { link: "", label: "Help And Support", icon: IconHeadset },
];

export function AppSideBar(props:any) {
  const [active, setActive] = useState(props.name);

  var links = data.map((item) => (
    <Link
      className={classes.link}
      data-active={item.label === active || undefined}
      to={item.link}
      key={item.label}
      onClick={(event) => {
        setActive(item.label);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.3} />
      <Text size="xs" fw={600} c="black">
        {item.label}
      </Text>
    </Link>
  ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarMain}>{links}</div>
    </nav>
  );
}
