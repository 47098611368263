import React, { useEffect, useState } from 'react';
import { Select, Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCaretDownFilled } from '@tabler/icons-react';

const DomainDropdown = ({ handleCurrentDomain, consentService, defaultSelection }) => {
  const [loading, setLoading] = useState(false);
  const [domainData, setDomainData] = useState([]);
  const [currentSelection, setCurrentSelection] = useState(defaultSelection);

  const getDomainData = async () => {
    setLoading(true);
    try {
      const response = await consentService.getDomains();
      console.log("API Response:", response); // Log the response

      if (response && response.data) {
        // Map API response to the required format for <Select />
        const formattedData = response.data.map((domain) => ({
          value: domain.domainId || domain.domain, // Use a unique identifier for value
          label: domain.domain || domain.domainId, // Display name for the dropdown
        }));

        setDomainData(formattedData);

        // If defaultSelection is null, use the first available domain
        if (!defaultSelection && formattedData.length > 0) {
          setCurrentSelection(formattedData[0].value);
          handleCurrentDomain(formattedData[0].value, formattedData[0].label); // Notify parent of the default selection
        }
      }
    } catch (error) {
      console.error("Error fetching domains:", error);
      showNotification({ color: "red", message: "Failed to fetch domains." });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDomainData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Group gap={15} style={{ display: domainData.length > 0 ? 'block' : 'none' }}>
      {domainData.length > 0 && (
        <Select
          allowDeselect={false}
          defaultValue={currentSelection}
          placeholder="Please select your Domain"
          data={domainData}
          onChange={(value, label) => {
            setCurrentSelection(value);
            handleCurrentDomain(value, label);
          }}
          disabled={loading}
          styles={{
            input: {
              border: 'none',
              borderBottom: '1px solid #868e96',
              borderRadius: '0px',
              fontSize: '14px',
              padding: '8px 0',
              textAlign: 'left',
              color: '#333',
            },
            dropdown: {
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              borderRadius: '4px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            },
          }}
          rightSection={<IconCaretDownFilled size={15} />}
        />
      )}
    </Group>
  );
};

export default DomainDropdown;
