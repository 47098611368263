import {
  Grid,
  Paper,
  Text,
  Title,
  Group,
  Stack,
  Button,
  Center,
  BackgroundImage,
  SimpleGrid,
  Select,
  Accordion,
  Radio,
  Checkbox,
  Divider,
  Tooltip,
  Flex,
  Popover,
} from "@mantine/core";
import {
  IconCircleCheckFilled,
  IconLayout,
  IconPalette,
  IconUpload,
  IconInfoCircle,
  IconLanguage,
} from "@tabler/icons-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import colorgradient from "../../../images/colorgradient.png";
import { Dropzone, MIME_TYPES, FileWithPath } from "@mantine/dropzone";
import { BannerTheme } from "./BannerTheme";
import { ButtonTheme } from "./ButtonTheme";
import { ThemeDetails } from "../../../Models/themeDetails";
import { ConsentOptionsConfig } from "../../../Models/consentOptionsConfig";
import { AdditionalDetailsConfig } from "../../../Models/additionalDetailsConfig";
import { ConsentConfig } from "../../../Models/consentConfig";
import './consent.css';
import { ConsentService } from "../../../services/consentService";
import { ConfigContext } from "../../../Context/configContext";
import { regulations } from "../../../utils/utils";
import { useDisclosure } from "@mantine/hooks";

const layouts = [
  {
    layoutType: "Box",
    bannerValue: "leftBottomPopUp",
    styles: {
      border: "1px solid #eaeaea",
      left: "7px",
      bottom: "7px",
      padding: "10px",
      position: "absolute",
      width: "40%",
    },
    text: "Box",
  },
  {
    layoutType: "Banner",
    bannerValue: "bannerBottom",
    styles: {
      border: "1px solid #eaeaea",
      left: "5px",
      bottom: "7px",
      padding: "5px",
      position: "absolute",
      width: "80%",
    },
    text: "Banner",
  },
  {
    layoutType: "PopUp",
    bannerValue: "popUpCenter",
    styles: {
      border: "1px solid #eaeaea",
      left: "30%",
      bottom: "30%",
      padding: "10px",
      position: "absolute",
      width: "40%",
    },
    text: "Popup",
  },
];

const consentOptionsData : ConsentOptionsConfig[] =  [{checked: true,label: "Necessary"},{checked: false,label: "Preferences"},{checked: false,label: "Statistics"},{checked: false,label: "Marketing"}];
const themesConfig: ThemeDetails[] = [
    {background:"#fff",type:"Light",buttonStyle:"Mixed",textColor:"#000",highlight:"#4CAF50",buttonCustomizeBackGround:"#0078b4",buttonCustomizeTextColor:"#fff",buttonCustomizeVariant:"filled",buttonCustomizeBorder:"#0078b4",buttonRejectBackGround:"#fff",buttonRejectTextColor:"#0078b4",buttonRejectVariant:"filled",buttonRejectBorder:"#0078b4",buttonAcceptBackGround:"#0078b4",buttonAcceptTextColor:"#fff",buttonAcceptVariant:"filled",buttonAcceptBorder:"#0078b4",consentOptions:consentOptionsData},
    {background:"#000",type:"Dark",buttonStyle:"Mixed",textColor:"#fff",highlight:"gray",buttonCustomizeBackGround:"#fff",buttonCustomizeTextColor:"#000",buttonCustomizeVariant:"filled",buttonCustomizeBorder:"#fff",buttonRejectBackGround:"#fff",buttonRejectTextColor:"#000",buttonRejectVariant:"filled",buttonRejectBorder:"#fff",buttonAcceptBackGround:"#fff",buttonAcceptTextColor:"#000",buttonAcceptVariant:"filled",buttonAcceptBorder:"#fff", consentOptions:consentOptionsData},
    {background:"#fff",type:"Custom",buttonStyle:"Mixed",textColor:"#000",highlight:"#0078b4",buttonCustomizeBackGround:"#0078b4",buttonCustomizeTextColor:"#fff",buttonCustomizeVariant:"filled",buttonCustomizeBorder:"#0078b4",buttonRejectBackGround:"#fff",buttonRejectTextColor:"#0078b4",buttonRejectVariant:"filled",buttonRejectBorder:"#0078b4",buttonAcceptBackGround:"#0078b4",buttonAcceptTextColor:"#fff",buttonAcceptVariant:"filled",buttonAcceptBorder:"#0078b4",consentOptions:consentOptionsData}
]
const additionalOptions: AdditionalDetailsConfig = {isPreferences:false,isStatistics:false,isMarketing:false,isDisplayCloseIcon:false}
const finalConsentConfig: ConsentConfig = {regulation:regulations[0], language:'en', layout:'Box',alignment:'leftBottomPopUp',theme:themesConfig[0],isLogoDisplay:true,logo:'',method:'',type:'',additionalDetailsConfig:additionalOptions, baseUrl: window.location.hostname};

const languageOptions = [{label:'Hindi',value:'hi'},{label:'Bengali',value:'bn'},{label:'Telugu',value:'tg'},{label:'Marathi',value:'mr'},{label:'Tamil',value:'ta'},{label:'Urdu',value:'ur'},{label:'Gujarati',value:'gr'},{label:'Kannada',value:'kn'},{label:'English',value:'en'},{label:'Odisha',value:'od'},{label:'Malayalam',value:'ml'},{label:'Punjabi',value:'pb'},{label:'Assam',value:'as'}];

declare var Cookiex: {
  new (): {
      init(theme: { domainId: string; domainName: string }): void;
  };
};

function Step5({ forward, backward, screenSize, consentData, consentConfigData, domainId, domainName}): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [layoutType, setLayoutType] = useState(consentConfigData?.layout || "Box");
  const [themeType, setThemeType] = useState(consentConfigData?.theme?.type || "Light");
  const [buttonStyle, setButtonStyle] = useState(consentConfigData?.theme?.buttonStyle || "Mixed");
  const [bannerValue, setBannerValue] = useState(consentConfigData?.alignment || "leftBottomPopUp");
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [tempFile, setTempFile] = useState<FileWithPath[]>([]);
  const [isLogoShown, SetLogoShown] = useState(true);
  const [language, setLanguage] = useState(consentConfigData?.language || 'en');
  const [customThemeStyle, setCustomThemeStyle] = useState<ThemeDetails>(consentConfigData?.theme || finalConsentConfig.theme);
  const [consentConfig, setConsentConfig] = useState<ConsentConfig>(consentConfigData || finalConsentConfig);
  const [selectedOptions, setAdditionalDetailsOptions] = useState<string[]>(['Necessary']);
  const [complianceMethod, setComplianceMethod] = useState('Explicit Consent');
  const [complianceType, setComplianceType] = useState("Multilevel");
  const initialRef = useRef(false);
  const [regulation, setRegulation] = useState<any>(consentConfigData?.regulation || regulations[0]);
  const [opened, { close, open }] = useDisclosure(false);

  const consentService = new ConsentService(config!);

  useEffect(() => {
       if(consentConfigData?.theme) {
           console.log(consentConfigData, selectedOptions)
           setCustomThemeStyle(consentConfigData.theme);
           setConsentConfig(consentConfigData);
           setComplianceMethod(consentConfigData.method);
           setComplianceType(consentConfigData.type);
           setAdditionalDetailsOptions(consentConfigData.theme.consentOptions.filter(opt => opt.checked).map(opt => opt.label));
           setLayoutType(consentConfigData.layout);
           setBannerValue(consentConfigData.alignment);
           setThemeType(consentConfigData.theme.type);
           setButtonStyle(consentConfigData.theme.buttonStyle);
           SetLogoShown(consentConfigData.isLogoDisplay);
           setLanguage(consentConfigData.language);
       }
       setTempFile([]);
       console.log(tempFile);
       if(!initialRef.current) {
          initialRef.current = true;
          generatePreview(true, regulation);
       }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentConfigData]);
  
  const generatePreview = (isPreview:boolean, regulationType:any) => {
      consentConfig.isLogoDisplay = isLogoShown;
      consentConfig.method = complianceMethod;
      consentConfig.type = complianceType;
      consentConfig.regulation = regulationType;
      if(files.length > 0) {
        const imageUrl = URL.createObjectURL(files[0]);
        consentConfig.logo = imageUrl;
      }

      if(isPreview) {
        generateHTML();
      }
  };

  const handleLanguage = (languageObj) => {
    setLanguage(languageObj);
    setTempFile([]);
    consentConfig.language = languageObj;
    generatePreview(true, regulation);
  };

  const generateHTML = () => {
      const loadExternalScript = (src) => {
        return new Promise<void>((resolve, reject) => {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = src;

          script.onload = () => {
              resolve();
          };

          script.onerror = () => {
              reject(new Error(`Failed to load script ${src}`));
          };

          document.head.appendChild(script);
        });
      }

      const scriptUrl = config?.bannerUrl + '/cookiex.min.js';

      setTimeout(function () {
        loadExternalScript(scriptUrl)
        .then(() => {
            const theme = {
                domainId: domainId,
                domainName: domainName,
                selectorId:'coookiex-comp-banner-preview',
                themeConfigs: consentConfig
            };

            const cookiex = new Cookiex();
            cookiex.init(theme);
        })
        .catch((error) => {
            console.error('Error loading external script:', error);
        });
      }, 1000);
  };

  const handleCustomThemeStyles = (styleType) => {
    const filteredTheme = themesConfig.filter(theme => theme.type === styleType);
    setCustomThemeStyle(filteredTheme[0]);
    consentConfig.theme = filteredTheme[0]; 
    generatePreview(true, regulation);
    setTempFile([]);
  };

  const handleButtonStyles = (styleType) => {
     const tempObj = customThemeStyle;
     setButtonStyle(styleType);
     if(styleType == 'Mixed') {
        tempObj.buttonCustomizeVariant = "filled";
        tempObj.buttonAcceptVariant = "filled";
        tempObj.buttonRejectVariant = "filled";
     } else if (styleType == 'Outline') {
        tempObj.buttonCustomizeVariant = "outline";
        tempObj.buttonAcceptVariant = "outline";
        tempObj.buttonRejectVariant = "outline";
     } else {
        tempObj.buttonCustomizeVariant = "filled";
        tempObj.buttonAcceptVariant = "filled";
        tempObj.buttonRejectVariant = "filled";
     }

     let textColor = "#000";
     
     if(customThemeStyle.type == "Light" && styleType == 'Outline') {
         textColor = "#0078b4";
     } else if(customThemeStyle.type == "Light" && styleType != 'Outline') {
         textColor = "#fff";
     }

     tempObj.buttonCustomizeTextColor = textColor;
     tempObj.buttonAcceptTextColor = textColor;
     tempObj.buttonRejectTextColor = textColor;
     tempObj.buttonStyle = styleType;
     setCustomThemeStyle(tempObj);
     consentConfig.theme = tempObj[0]; 
     generatePreview(true, regulation);
     setTempFile([]);
  };

  const handleCustomStyles = (colorValue, type) => {
     const tempObj = customThemeStyle;
     if(type == 'Background') {
        tempObj['background'] = colorValue;
     } else if (type == 'Text') {
        tempObj['textColor'] = colorValue;
     } else {
        tempObj['highlight'] = colorValue;
     }
     setCustomThemeStyle(tempObj);
     consentConfig.theme = tempObj; 
     generatePreview(true, regulation);
     setTempFile([]);
  };

  const handleCustomButtonStyles = (colorValue, buttonType, type) => {
        const tempObj = customThemeStyle;
        if(buttonType == 'buttonCustomize') {
            if(type == 'Background') {
                tempObj['buttonCustomizeBackGround'] = colorValue;
             } else if (type == 'Text') {
                tempObj['buttonCustomizeTextColor'] = colorValue;
             } else {
                tempObj['buttonCustomizeBorder'] = colorValue;
             }
        } else if(buttonType == 'buttonReject') {
           if(type == 'Background') {
               tempObj['buttonRejectBackGround'] = colorValue;
            } else if (type == 'Text') {
               tempObj['buttonRejectTextColor'] = colorValue;
            } else {
               tempObj['buttonRejectBorder'] = colorValue;
            }
        } else if(buttonType == 'buttonAccept') {
            if(type == 'Background') {
                tempObj['buttonAcceptBackGround'] = colorValue;
             } else if (type == 'Text') {
                tempObj['buttonAcceptTextColor'] = colorValue;
             } else {
                tempObj['buttonAcceptBorder'] = colorValue;
             }
        }
        setCustomThemeStyle(tempObj);
        consentConfig.theme = tempObj; 
        generatePreview(true, regulation);
        setTempFile([]);
  };

  const handleUpload = (file) => {
     setFiles(file);
     setTempFile(file);
     consentService.uploadClientLogo(domainId, file);
  };

  const saveConsentBannerConfig = (isModal) => {
      generatePreview(false, regulation);
      consentData(consentConfig, "styles");
      if(isModal) {
        forward(6);
        screenSize("55%");
      }
  };

  return (
    <React.Fragment>
      <>
        {screenSize && (
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                <Center>
                  <Stack>
                    <Title style={{ textAlign: "center" }} order={2}>Configure Your Consent Banner</Title>
                    <Text mb="xl" size="xs">Select a predefined banner template for the legislation you want to enable compliance for on your domain</Text>
                  </Stack>
                </Center>
              </Grid.Col>
            </Grid>
         )}
         <Grid>
          <Grid.Col span={{ base: 12, md: 5, lg: 3 }} style={{ borderRadius: "8px", background: screenSize?"#f2f4fa":"#fff" }}>
            <div>
              <Paper withBorder shadow="0">
                <Accordion transitionDuration={0} defaultValue="layout">
                  <Accordion.Item value="layout">
                    <Accordion.Control style={{ borderBottom: "1px solid #f2f4fa" }}>
                      <Group justify="left">
                        <IconLayout />
                        <Text size="xs"> Layout </Text>
                      </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Text size="xs" mb="xs" mt="xs" fw={500}>Consent Template</Text>
                      <Flex>                      
                        <Select mb={10} mr={10}
                          placeholder="Choose a regulation"
                          allowDeselect={false}
                          searchable
                          data={regulations.map((reg) => (
                              { value: reg.value, label: reg.label }
                          ))}
                          defaultValue={regulation.value}
                          onChange={(value) => {
                            const selectedRegulation = regulations.find((reg) => reg.value === value);
                            setRegulation(selectedRegulation);
                            console.log('Selected Regulation:', selectedRegulation);
                            generatePreview(true, selectedRegulation);
                          }}
                        />
                        <Popover width={300} position="right" withArrow shadow="md" opened={opened}>
                          <Popover.Target>
                            <IconInfoCircle onMouseEnter={open} onMouseLeave={close} />
                          </Popover.Target>
                          <Popover.Dropdown style={{ pointerEvents: 'none' }}>
                            <Text size="sm">{regulation?.description}</Text>
                          </Popover.Dropdown>
                        </Popover>
                      </Flex>
                      <Text size="xs" mb="xs" fw={500}>Banner Layout{" "}</Text>
                      <Grid>
                        {layouts.map((layout:any, index) => (
                          <Grid.Col key={index} span={{ base: 12, md: 4, lg: 4 }}>
                            <div>
                              <Paper
                                shadow="0px"
                                withBorder
                                p="lg"
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  height: "60px",
                                }}
                                onClick={() => {
                                  setLayoutType(layout.layoutType);
                                  setBannerValue(layout.bannerValue);
                                  consentConfig.layout = layout.layoutType;
                                  consentConfig.alignment = layout.bannerValue;
                                  generatePreview(true, regulation);
                                }}
                              >
                                <div
                                  className="fill-primary"
                                  style={layout.styles}
                                ></div>
                                {layoutType === layout.layoutType && (
                                  <IconCircleCheckFilled
                                    className="primary"
                                    size="1rem"
                                    stroke={3}
                                    style={{ left: "-8px", top: "-7px", position: "absolute" }}
                                  />
                                )}
                              </Paper>
                              <Text size="xs" mt={5} ta="center">
                                {layout.text}
                              </Text>
                            </div>
                          </Grid.Col>
                        ))}
                      </Grid>
                      {layoutType == "Box" && (
                        <Radio.Group mt={15} mb={15} value={bannerValue}
                        onChange={(e : any) => {
                          setBannerValue(e);
                          consentConfig.alignment = e;
                          generatePreview(true, regulation);
                        }}>
                          <SimpleGrid cols={2}>
                            <Radio size="xs" value="leftBottomPopUp" label="Bottom Left" />
                            <Radio size="xs" value="rightBottomPopUp" label="Bottom Right" />
                            <Radio size="xs" value="leftTopPopUp" label="Top Left" />
                            <Radio size="xs" value="rightTopPopUp" label="Top Right" />
                          </SimpleGrid>
                        </Radio.Group>
                      )}
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value="design">
                    <Accordion.Control style={{ borderBottom: "1px solid #f2f4fa" }}>
                      <Group justify="left">
                        <IconPalette />
                        <Text size="xs"> Design </Text>
                      </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Select mt={10} label="Theme" allowDeselect={false} defaultValue={themeType} onChange={(event) => handleCustomThemeStyles(event)} data={["Light", "Dark", "Custom"]} />
                      <div>
                      {customThemeStyle?.type == 'Custom' && (
                        <div>
                            <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle.background} label="Background" description="" />
                            <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle.textColor} label="Text" description="Choose the color of all texts within the banner" />
                            <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle.highlight} label="Highlight" description="Choose your highlight color that will impact all links and active toggles in your banner" />
                            <Text mt="sm" size="xs" ta="center" c="blue" onClick={() => handleCustomThemeStyles('Light')}>Reset all banner consent</Text>
                            <Divider my="sm" />
                            <Text mt="sm" size="xs" c="blue">Button Colors</Text>
                            <Accordion transitionDuration={0} variant="contained" mt={5}>
                              <Accordion.Item value="customize" mt={5}>
                                <Accordion.Control>
                                  <Group justify="left"><Text size="xs"> Button 1 (Customize) </Text></Group>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonCustomizeBackGround} label="Background" type="buttonCustomize" description="" />
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonCustomizeTextColor} label="Text" type="buttonCustomize" description="" />
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonCustomizeBorder} label="Border" type="buttonCustomize" description="" />
                                </Accordion.Panel>
                              </Accordion.Item>
                              <Accordion.Item value="reject" mt={5}>
                                <Accordion.Control>
                                  <Group justify="left">
                                    <Group justify="left"><Text size="xs"> Button 2 (Reject All) </Text></Group>
                                  </Group>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonRejectBackGround} label="Background" type="buttonReject" description="" />
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonRejectTextColor} label="Text" type="buttonReject" description="" />
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonRejectBorder} label="Border" type="buttonReject" description="" />
                                </Accordion.Panel>
                              </Accordion.Item>
                              <Accordion.Item value="accept" mt={5}>
                              <Accordion.Control>
                                <Group justify="left">
                                  <Group justify="left"><Text size="xs"> Button 2 (Accept) </Text></Group>
                                </Group>
                              </Accordion.Control>
                              <Accordion.Panel>
                                   <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonAcceptBackGround} label="Background" type="buttonAccept" description="" />
                                   <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonAcceptTextColor} label="Text" type="buttonAccept" description="" />
                                   <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonAcceptBorder} label="Border" type="buttonAccept" description="" />
                              </Accordion.Panel>
                            </Accordion.Item>
                           </Accordion>
                        </div>
                      )}
                      {customThemeStyle?.type != 'Custom' && (
                        <Select mt={10} allowDeselect={false} label="Button Style" defaultValue={buttonStyle} onChange={(event) => handleButtonStyles(event)}  data={["Outline", "Mixed", "Solid"]} />
                      )}
                      </div>
                      <Group justify="left" mt={10}>
                          <Title order={6} fw={500}>Logo</Title>
                          <Tooltip label="Add a logo to display on your banner">
                            <IconInfoCircle size="14"/>
                          </Tooltip>
                      </Group>
                      <Checkbox mt={4}
                        mb={10}
                        checked={isLogoShown}
                        onChange={(event) => SetLogoShown(event.currentTarget.checked)}
                        label="Display logo on banner"
                      />
                      <Dropzone
                        maxFiles={1}
                        accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.svg, MIME_TYPES.gif]}
                        onDrop={handleUpload}
                      >
                        <Group justify="center">
                          <IconUpload size={15} color="blue" />
                          <Text size="xs" ta="center" c="blue">
                            Upload File
                          </Text>
                        </Group>
                      </Dropzone>
                      <Text size="xs" mt={10}>
                        Filetype jpg, jpeg, gif, svg
                      </Text>
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value="content">
                    <Accordion.Control style={{ borderBottom: "1px solid #f2f4fa" }}>
                      <Group justify="left">
                        <IconLanguage />
                        <Text size="xs"> Content </Text>
                      </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      {languageOptions.length > 0 && (
                          <Select mt={10} allowDeselect={false} defaultValue={language} label="Language" data={languageOptions} onChange={(event) => handleLanguage(event)} />
                      )}
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </Paper>
            </div>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 7, lg: 9 }}>
            <div style={{ width: "100%", position: "relative", maxWidth: '1000px !important' }}>
              <BackgroundImage 
                  w="100%" 
                  mih={450} 
                  src={colorgradient} 
                  radius="sm"
                  style={{ 
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                  }}
              >
                  <div style={{
                      height: '100%', 
                      width: '100%',
                      minHeight: '450px'
                  }} id="coookiex-comp-banner-preview" />
              </BackgroundImage>
            </div>
            {screenSize && (
            <Group mt={20}>
                <Button
                  w={120}
                  variant="outline"
                  size="sm"
                  onClick={(event) => {
                    backward(4);
                    screenSize("55%");
                  }}
                >
                  Back
                </Button>
                <Button w={120} variant="outline" size="sm" color="blue"onClick={(event) => {generatePreview(true, regulation);}}>Preview</Button>
                <Button
                  w={120}
                  variant="filled"
                  size="sm"
                  onClick={(event) => {
                    saveConsentBannerConfig(true);
                  }}
                >
                  Next
                </Button>
              </Group>
              )}
              {!screenSize && (
                <Group>
                   <Button variant="filled" mt="xs" size="sm" onClick={(event) => {saveConsentBannerConfig(false);}}>
                      Publish Changes
                   </Button>
               </Group>
              )}
          </Grid.Col>
        </Grid>
      </>
    </React.Fragment>
  );
}

export { Step5 };
