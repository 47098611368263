import React, { useState, useContext } from "react";
import { Group, LoadingOverlay, Modal, Stepper, Text } from "@mantine/core";
import { AddDomain } from "./AddDomain";
import { ConsentConfiguration } from "./ConsentConfiguration";
import { consentBannerConfig } from "../../../Models/consentBannerConfig";
import { showNotification } from "@mantine/notifications";
import { ConsentService } from "../../../services/consentService";
import { ConfigContext } from "../../../Context/configContext";
import { Integration } from "./Integration";
import { Completion } from "./Completion";

const tempObj = {language:'en',domainId:'',domainUrl:'',geoLocations:[],consentExpire:'',styles:'',locations:[]};
function Wizard(props) {
  const [opened, setOpened] = useState(true);
  const [activeStep, setActiveStep] = useState(1);
  const { config } = useContext(ConfigContext);
  const [domainId, setDomainId] = useState('');
  const [domainName, setDomainName] = useState('');
  const [consentConfigRequest ] = useState<consentBannerConfig>(tempObj);
  const [loading, setLoading] = useState(false);
  
  const consentService = new ConsentService(config!);

  const handleConsentDomain = (fieldValue, fieldName) => {
    consentConfigRequest[fieldName] = fieldValue;
    setDomainName(fieldValue);
    nextStep();
  };

  const handleConsentExpire = (fieldValue, fieldName) => {
    consentConfigRequest[fieldName] = fieldValue;
  };

  const handleConsentData = async (fieldValue, fieldName) => {
    setLoading(true);
  
    // Update the appropriate field in consentConfigRequest
    consentConfigRequest[fieldName] = fieldName === 'styles' ? JSON.stringify(fieldValue) : fieldValue;
  
    try {
      let resp;
      
      // Decide whether to call saveConsent or updateConsent based on fieldName
      resp = await consentService.saveConsent(consentConfigRequest);

      // Handle response based on status
      if (resp?.id) {
        const { id, domainUrl } = resp;  // Assuming response has data with id and domainUrl
        setDomainId(id);
        setDomainName(domainUrl);
        nextStep();
      } else if (resp.status >= 400 && resp.status < 500) {
        showNotification({ color: "red", message: "Client error: Check input data." });
      } else if (resp.status >= 500) {
        showNotification({ color: "red", message: "Server error: Please try again later." });
      }
    } catch (error:any) {
      showNotification({ color: "red", message: error.response.data });
    } finally {
      setLoading(false);  // Ensure loading is turned off in all cases
    }
  };
  
  const nextStep = async () => {
    await new Promise((resolve) => setTimeout(resolve, 500)); // Simulate delay
    setActiveStep((current) => current + 1);
  };

  const prevStep = () => setActiveStep((current) => (current > 0 ? current - 1 : current));
  
  const close = () => setOpened(false);

  return (
    <React.Fragment>
        <>
            <Modal withCloseButton={false} opened={opened} onClose={close} centered>
                <Stepper active={activeStep} onStepClick={setActiveStep} size="md" mt="xl">
                    <Stepper.Step label="" />
                    <Stepper.Step label="" />
                    <Stepper.Step label="" />
                    <Stepper.Step label="" />
                    <Stepper.Step label="" />
                </Stepper>

                <Group justify="right" pr={20} pt={10}>
                    <Text size="xs" style={{ cursor: 'pointer' }} onClick={close}>Skip</Text>
                </Group>

                {activeStep === 1 && (
                    <>
                        {loading && (
                            <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
                        )}
                        
                        <AddDomain domainName={domainName} handleConsentDomain={handleConsentDomain} />
                    </>
                )}
                {activeStep === 2 && (
                 <>
                    {loading && (
                        <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
                    )}
                    
                    <ConsentConfiguration prevStep={prevStep} consentData={handleConsentData} handleExpire={handleConsentExpire} /> 
                 </>
                )}
                {activeStep === 3 && <Integration nextStep={nextStep} prevStep={prevStep} domainId={domainId} domainName={domainName} />}
                {activeStep === 4 && <Completion prevStep={prevStep} close={close} domainId={domainId} domainName={domainName} />}
            </Modal>
        </>
    </React.Fragment>
  );
}

export { Wizard };
