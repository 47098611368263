import {
  LoadingOverlay,
  TextInput,
  Paper,
  Text,
  Button,
  Image,
  Title,
  Space,
  Grid,
  Card,
  Flex,
  Center,
  em
} from "@mantine/core";
import React, { useContext, useState } from "react";
import logo from "../images/logo.png";
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { Link } from 'react-router-dom';
import loginBanner from '../images/login_banner.jpg';
import './login.css';
import { useMediaQuery } from '@mantine/hooks';
import { ConfigContext } from "../Context/configContext";
import { AuthService } from "../services/AuthService";

function ResetPassword(): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState("01:");
  const [seconds, setSeconds] = useState(60);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [emailId, setEmailId] = useState('');
  const [title, setTitle] = useState('Forgot your password');
  const [isResend, setIsResend] = useState(false);
  const [message, setMessage] = useState("An email will be sent to your email address with a link to reset your password");
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const authService = new AuthService(config!);

  const form = useForm({
      initialValues: {name:'',email: '',password: ''},
      validate: {
        email: (value) => !value ? 'Email is required':(emailPattern.test(value)? null : 'Invalid email')
      },
  });


  const timer = () => {
    let intervalId;
    let secondsLeft = 60;
    setTime('01:');
    intervalId = setInterval(() => {
      setTime('00:');
      if (secondsLeft > 0) {
        setSeconds(secondsLeft);
        setSeconds(secondsLeft - 1);
      } else {
        setIsResend(true);
        clearInterval(intervalId);
      }
      secondsLeft--;
    }, 1000);
  };

  const resendVerificationEmail = async () => {
      setLoading(true);
      try {
          if(emailId) {
              await authService.resetPassword(emailId).then((res:any) => {
                   setLoading(false)
                   if (res?.statusText == 'OK') {
                     setIsEmailSent(true);
                     setTimeout(function () {
                        setSeconds(60);
                        setIsResend(false);
                        timer();
                        showNotification({ color: "green", message: "Verification email resent successfully"});
                        setLoading(false);
                     }, 1000);
                   } else {
                       if(res?.response?.data == 'Failed to send verification link') {
                           setIsEmailSent(false);
                       }
                       showNotification({ color: "red", message: res?.response?.data});
                   }
              })
          }
      } catch (error) {
           showNotification({ color: "red", message: "Something went wrong !" });
      }
  };

  const resetPasswordEmail = async (values:any) => {
      setLoading(true)
      try {
          if(values.email) {
              setEmailId(values.email);
              await authService.resetPassword(values.email).then((res:any) => {
                   setLoading(false)
                   if (res?.statusText == 'OK') {
                       setIsEmailSent(true);
                       setTimeout(function () {
                         setTitle('Please Check Your Inbox');
                         setMessage('An email will be sent to your email address with a link to reset your password');
                         showNotification({ color: "green", message: "Verification email resent successfully"});
                         timer();
                         setLoading(false);
                       }, 1000);
                   } else {
                       if(res?.response?.data == 'Failed to send verification link') {
                           setIsEmailSent(false);
                       }
                       showNotification({ color: "red", message: res?.response?.data});
                   }
              })
          }
      } catch (error) {
           showNotification({ color: "red", message: "Something went wrong !" });
      }
  };

  return (
    <React.Fragment>
      {loading && (
        <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
      <>
      <div className="login-page">
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }} mr={0} bg="#F4F6FB" visibleFrom="sm">
              <Card bg="#F4F6FB">
                <Card.Section  className="login-image">
                  <Text ta="center" c="black" fw="700" className="large-text" mb={40}>
                      Empower your website's data privacy with Cookiex to seamlessly
                      manage cookie consent and compliance today!
                  </Text>
                  <Image src={loginBanner} />
                </Card.Section>
              </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }} ml={0} mt={isMobile?80:0}>
              <Paper className="login-left">
                   <div>
                     <Image maw={120} mx="auto" mt="lg" radius="md" src={logo} />
                     <Title order={3} ta="center" mt="md">{title}</Title>
                     <Paper className="login-padding">
                        <Text size="sm" ta="center" mt="sm" mb="xl">{message}</Text>
                        {!isEmailSent && (
                            <form onSubmit={form.onSubmit(resetPasswordEmail)}>
                                <Title order={6} mt="md" mb="xs">Email address</Title>
                                <TextInput withAsterisk  radius="xs" size="md"  placeholder="name@example.com"  {...form.getInputProps('email')} />
                                <Button type="submit" fullWidth mt="xl" mb="xs"> Continue </Button>
                                <Link to="/login">
                                    <Flex justify="center">
                                        <Text ta="center" c="black" size="sm" mt={20} fw={500}>
                                           Back to
                                        </Text>
                                        <Space w={3} />
                                        <Text ta="center" c="blue" size="sm" mt={20} fw={500}>
                                             Login
                                        </Text>
                                    </Flex>
                                </Link>
                            </form>
                        )}

                        {isEmailSent && (
                           <div>
                                <Center mt="xl">
                                    <Text>Didn't receive email? Resend in </Text>
                                    <Space w={5} />
                                    <Text fw={500}>{time}</Text>
                                    <Text fw={500}>{seconds < 10?'0'+seconds:seconds==60?'00':seconds}</Text>
                                </Center>
                               <div>
                                 {isResend && (
                                      <Button type="submit" onClick={resendVerificationEmail} fullWidth mt="xl" mb="xs"> Resend </Button>
                                  )}
                                  {!isResend && (
                                    <Button disabled fullWidth mt="xl" mb="xs"> Resend </Button>
                                  )}
                               </div>
                          </div>
                        )}
                     </Paper>
                   </div>
              </Paper>
          </Grid.Col>
        </Grid>
      </div>
      </>
    </React.Fragment>
  );
}

export { ResetPassword };
